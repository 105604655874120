import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../src/theme';

export default function TopLayout(props) {
  return (
    <React.Fragment>
      <Helmet>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap"
          rel="stylesheet"
        />

        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "name": "TxtWizard",
              "url": "https://txtwizard.net",
              "description": "TxtWizard provides various encryption tools such as AES/GCM and AES/CBC, hash generating tools like MD5, SHA1, SHA256, SHA3, and encoding options including Base64 and HEX. Try our easy-to-use online tools today!"    
            }
          `}
        </script>
      </Helmet>
      <ThemeProvider theme={theme}>        
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
